import fetch from 'unfetch'
import { getTranslation } from '../i18n/translate'

// Not all the configs seem consitant with naming so just normalizing it.
const normalizeName = (name) => name.replace(/\s/g, '').toLowerCase()

// Basic color and text for the sections
const sectionData = {
  home: {
    id: 'home',
    color: '#707692',
    text: getTranslation('sections.home'),
  },
  favorites: {
    id: 'pinnedApps',
    color: '#707692',
    text: getTranslation('sections.pinnedApps'),
  },
  administration: {
    id: 'administration',
    color: '#707692',
    text: getTranslation('sections.administration'),
  },
  analytics: {
    id: 'analytics',
    color: '#A3498A',
    text: getTranslation('sections.analytics'),
  },
  contentcreation: {
    id: 'contentCreation',
    color: '#29AE5B',
    text: getTranslation('sections.contentCreation'),
  },
  content: {
    id: 'content',
    color: '#29AE5B',
    text: getTranslation('sections.content'),
  },
  debugging: {
    id: 'debugging',
    color: '#EB6263',
    text: getTranslation('sections.debugging'),
  },
  delivery: {
    id: 'delivery',
    color: '#ECB465',
    text: getTranslation('sections.delivery'),
  },
  experiences: {
    id: 'experiences',
    color: '#ECB465',
    text: getTranslation('sections.experiences'),
  },
  preview: {
    id: 'preview',
    color: '#707692',
    text: getTranslation('sections.preview'),
  },
  planning: {
    id: 'planning',
    color: '#43B8EE',
    text: getTranslation('sections.planning'),
  },
  revenue: {
    id: 'revenue',
    color: '#EB6263',
    text: getTranslation('sections.revenue'),
  },
  commerce: {
    id: 'commerce',
    color: '#EB6263',
    text: getTranslation('sections.commerce'),
  },
}

// Section order is added to the array.
// (Could have added index param to sectionData)
const sectionOrder = [
  'home',
  'Favorites',
  'Content Creation',
  'Content',
  'Experiences',
  'Delivery',
  'Planning',
  'Commerce',
  'Revenue',
  'Administration',
  'Analytics',
  'Debugging',
].reduce((o, k, i) => {
  o[normalizeName(k)] = i + 1
  return o
}, {})

// If a developer sets a section that we have no clue what it is
// We can set up a default color for it and basic text for translation
const getSectionDetails = (sectionName) => {
  const key = normalizeName(sectionName)
  const data = sectionData[key]
  return data || { color: '#707692', text: sectionName }
}

// This is the export that developer calls to get the dashboard with the
// section and application correctly sorted.
// If no basePath is supplied, it used the current pages location
export const fetchDashboardData = (
  basePath = window.location.origin,
  includeHomeTile = false
) => {
  // Need to set the cors so we can make the cross domain call
  // when we read dashboard for the sandbox and other environments
  const fetchSettings = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
  }

  const dashboardFetch = fetch(
    `${basePath}/dashboard.json`,
    fetchSettings
  ).then((resp) => {
    if (resp.ok) {
      return resp.json().then((body) => ({
        ...body,
        ...{ headers: resp.headers },
      }))
    }
    throw new Error('failed to fetch app data')
  })
  // this will eventually have to use the basepath
  const favoritesFetch = getFavorites()

  return Promise.all([dashboardFetch, favoritesFetch])
    .then(([dashboardData, userFavorites = []]) => {
      // This will hold the sections we find as we loop over the applications
      const sections = {
        favorites: {
          id: sectionData.favorites.id,
          section: 'favorites',
          apps: [],
          text: sectionData.favorites.text,
        },
      }
      // not all results need home tile added, so if the Boolean is set, we
      // will add it to the favorites list.
      if (includeHomeTile) {
        const homeTile = {
          href: basePath,
          title: 'Home',
          icon: 'home',
          img: 'https://resources.arcpublishing.com/apps/generic_navy.svg',
          section: 'Home',
          groups: null,
        }
        dashboardData.applications.unshift(homeTile)
        // userFavorites.unshift('home')
      }
      // loop over the applications so we can group the applications by section
      dashboardData.applications
        // make sure they have a section and title (so they have a tile)
        .filter((app) => app.section && app.title)
        // sort them so they are in alphabetical order
        .sort((a, b) => a.title.localeCompare(b.title))
        // loop over so we can group them into their appropriate sections
        .forEach((app) => {
          // check to see if the user favorited the application tile
          const isFavorite = userFavorites.includes(normalizeName(app.title))
          // if they did, move it out of its default section
          const normSectionName = isFavorite
            ? 'favorites'
            : normalizeName(app.section)
          const sectionName = isFavorite ? 'Favorites' : app.section
          if (isFavorite) {
            app.favIndex = userFavorites.indexOf(normalizeName(app.title))
          }

          // If we have not seen this section, that create array to hold it
          if (!sections[normSectionName]) {
            // Get details from local section data
            const details = getSectionDetails(sectionName)
            const { id, text, color = '#707692' } = details
            // create the object with the array
            sections[normSectionName] = {
              id,
              color,
              text,
              section: sectionName,
              apps: [],
            }
          }
          // add color to app so we do not need to pass along section data through every rendering step
          app.color = app.color || sections[normSectionName].color
          // push the app to the section
          sections[normSectionName].apps.push(app)
        })

      // They want it by alpha it seems... leaving this in case they change their mind
      // sort favorites by order in array, not by alpha
      // if (sections.favorites && sections.favorites.apps.length > 1) {
      //  sections.favorites.apps.sort((a, b) => a.favIndex > b.favIndex ? 1 : -1)
      // }

      // Return an array that has the sections corrected sorted
      // Order is determined by a defined set above
      // If items are not in the defined set, we sort by text
      dashboardData.orderedSections = Object.keys(sections)
        .sort((a, b) => {
          // If we have a defined index, use it, else just set a high number
          const aI = sectionOrder[a] || Number.MAX_VALUE
          const bI = sectionOrder[b] || Number.MAX_VALUE
          // If the index is the same (not defined above)
          // then we sort by the section text
          if (aI === bI) {
            return (sections[a].text || '').localeCompare(
              sections[b].text || ''
            )
          } else {
            // If we have different indexes, sort by the index
            return aI < bI ? -1 : 1
          }
        })
        .map((key) => sections[key])

      for (let headerName of dashboardData.headers.keys()) {
        if (headerName.startsWith('arc-')) {
          dashboardData.isBehindTrident = true
          break
        }
      }

      // return the dashboard json with the added ordered sections
      return dashboardData
    })
    .catch((e) => {
      console.error(e)
      return []
    })
}

const filterSections = (filter, section) => {
  // const matchSection = section.text.toLowerCase().includes(filter)
  // if (matchSection) {
  //   return section
  // }
  const apps = section.apps.filter((app) => filterItems(filter, app))
  if (apps.length > 0) {
    const sectionCopy = Object.assign({}, section, { apps })
    return sectionCopy
  }
  return null
}

const filterItems = (filter, item) => {
  // see if the title has a match with the filter
  const titleHasMatch = item.title.toLowerCase().includes(filter)
  if (titleHasMatch) {
    return true
  }
  // See if we have any sub navigation items to match
  if (item.secondary) {
    return item.secondary.some((sub) =>
      sub.title.toLowerCase().includes(filter)
    )
  }
  // no matches so do not render this item
  return false
}

export const filterApplications = (sectionsList, filterText) => {
  return sectionsList.reduce((filtered, current) => {
    const result = filterSections(filterText.toLowerCase(), current)
    if (result) {
      filtered.push(result)
    }
    return filtered
  }, [])
}

const pinnedApi = `${window.location.origin}/home/api/settings/v1/user/pinned`
const getFavorites = () => {
  const basepath = window.location.origin
  if (basepath.indexOf('localhost') > -1) {
    return []
  }

  const fetchSettings = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(pinnedApi, fetchSettings)
    .then((response) => {
      return response.json()
    })
    .then((settings) => settings.pinned || [])
}

export const addToFavorites = (appName) => {
  const normalized = normalizeName(appName)
  const fetchSettings = {
    method: 'POST',
    credentials: 'include',
  }
  return fetch(`${pinnedApi}/${normalized}`, fetchSettings)
    .then((response) => {
      return response.json()
    })
    .then((settings) => settings.pinned || [])
}

export const removeFromFavorites = (appName) => {
  const normalized = normalizeName(appName)
  const fetchSettings = {
    method: 'DELETE',
    credentials: 'include',
  }
  return fetch(`${pinnedApi}/${normalized}`, fetchSettings)
    .then((response) => {
      return response.json()
    })
    .then((settings) => settings.pinned || [])
}
