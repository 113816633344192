import React, { Component } from 'react'
import styles from './UserDropdown.css'
import faUser from '../../assets/img/fa/user.svg'
import fetch from 'unfetch'
import { getLinkTranslation, getTranslation } from '../i18n/translate.js'
import features from '../../assets/featureFlags'

class UserDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { user: {}, isOpen: false }

    this.setDropdownElement = (el) => {
      this.dropdownElement = el
    }

    this.toggle = this.toggle.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  handleOutsideClick(e) {
    if (!this.dropdownElement.contains(e.target)) {
      this.toggle()
    }
  }

  linkClicked(link, event) {
    this.props.clickHandler(link, event)
    this.toggle()
  }

  toggle() {
    this.state.isOpen
      ? document.removeEventListener('click', this.handleOutsideClick)
      : document.addEventListener('click', this.handleOutsideClick)

    this.setState((prev) => ({ isOpen: !prev.isOpen }))
  }

  fetchUser() {
    return fetch('/user.json', { credentials: 'include' })
      .then((resp) => {
        if (resp.ok) {
          return resp.json()
        }
        throw new Error('failed to fetch user data')
      })
      .catch((e) => {
        if (window && window.location.host.indexOf('localhost') === -1) {
          console.error(e)
        }
        throw new Error('getUserError')
      })
  }

  async componentDidMount() {
    try {
      const f = await features()
      this.setState({
        profileSettingsEnabled: f.isEnabled('userProfile'),
      })

      this.mounted = true
      const user = await this.fetchUser()
      if (this.mounted) {
        this.setState({ user })
      }
    } catch (err) {
      if (err.message.includes('getUserError')) {
        this.setState({ error: 'getUserError' })
      } else {
        this.setState({ error: 'miscFeaturesError' })
      }

      if (this.mounted) {
        this.setState({ user: {} })
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false // hack instead of aborting fetch request
  }

  render() {
    const { links, version } = this.props
    const { user, isOpen } = this.state
    const menuClass =
      styles.menu + ' ' + styles.right + (isOpen ? ' ' + styles.open : '')

    const linkElements = (links || []).map((l) => {
      return (
        <li key={l.name}>
          <a
            href={l.href}
            target={l.target}
            onClick={this.linkClicked.bind(this, l)}
          >
            {getLinkTranslation(l)}
          </a>
        </li>
      )
    })

    return (
      <div
        className={styles.dropdown}
        ref={this.setDropdownElement}
        data-testid="arc-user-dropdown"
      >
        <div className={styles.toggle} onClick={this.toggle}>
          {user.displayName ? (
            <span
              className={styles.displayName}
              data-testid="user-display-name"
            >
              {user.displayName}
            </span>
          ) : (
            undefined
          )}
          <img src={faUser} alt="user-icon" />
        </div>
        <ul className={menuClass} data-testid="profile-dropdown">
          {linkElements}
          {this.state.profileSettingsEnabled && (
            <li data-testid="user-profile-link">
              <a href="/home/profile/settings/">
                {getTranslation('profileSettings')}
                <span className={`${styles.right} ${styles.newItemTag}`}>
                  {getTranslation('profileNewTag')}
                </span>
              </a>
            </li>
          )}
          <li>
            <a href="/logout" onClick={this.toggle}>
              {getTranslation('logout')}
            </a>
          </li>
          {version ? (
            <li>
              <div className={styles.version}>
                {getTranslation('version')} {version}
              </div>
            </li>
          ) : (
            undefined
          )}
          {this.state.error && (
            <li>
              <div className={styles.error}>
                {getTranslation(`errors.${this.state.error}`)}
              </div>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default UserDropdown
