import React, { Component } from 'react'
import styles from './EnvLabel.css'
import fetch from 'unfetch'
import { getTranslation } from '../i18n/translate.js'

class EnvLabel extends Component {
  constructor(props) {
    super(props)
    this.state = { name: '', production: false }
  }

  fetchDashboard() {
    return fetch('/dashboard.json', { credentials: 'include' }).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }

      throw new Error('failed to fetch user data')
    })
  }

  componentDidMount() {
    this.mounted = true
    this.fetchDashboard()
      .then(
        ({ name, production }) =>
          this.mounted && this.setState({ name, production })
      )
      .catch(
        (e) => this.mounted && this.setState({ name: '', production: false })
      )
  }

  componentWillUnmount() {
    this.mounted = false // hack instead of aborting fetch request
  }

  render() {
    const { name, production } = this.state
    return (
      <div
        className={styles.env + (production ? ` ${styles.production}` : '')}
        data-testid="environment-label"
      >
        {getTranslation(name)}
      </div>
    )
  }
}

export default EnvLabel
