import React, { Component } from 'react'
import ReactModal from 'react-modal'
import reactModalStyle from './reactModalStyle.js'
import { getTranslation, getBrowserLocale } from '../i18n/translate'
import styles from './Modal.css'

const TIME_FORMAT_OPTION = {
  hour: 'numeric',
  minute: '2-digit',
  // timezone doesn't work for IE11, so it won't display this
  timeZoneName: 'short',
}

class SessionWarningModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      minutes: this.getMinutesLeft(),
    }
  }

  componentWillUnmount() {
    this.timer && window.clearInterval(this.timer)
  }

  getMinutesLeft() {
    const diff = this.props.globalTimeout - new Date().getTime()
    let minutes = Math.floor(diff / 60000)
    if (minutes < 0) {
      return 0
    }
    return minutes
  }

  render() {
    const locale = getBrowserLocale(window.navigator)
    const timeStr = new Date(this.props.globalTimeout).toLocaleTimeString(
      locale,
      TIME_FORMAT_OPTION
    )
    if (this.timer) window.clearInterval(this.timer)
    this.timer = window.setInterval(() => {
      this.setState({ minutes: this.getMinutesLeft() })
      if (this.props.wasWarningAcknowledged()) {
        this.props.confirm()
      }
    }, 300)

    return (
      <ReactModal
        isOpen
        style={reactModalStyle}
        ariaHideApp={false}
        portalClassName={styles['modal-portal']}
      >
        <div className={styles['modal-content']}>
          <h3>
            {getTranslation('sessionTimeout.warningHeader', {
              minutes: this.state.minutes,
            })}
          </h3>
          <div>
            {getTranslation('sessionTimeout.warningBody', { time: timeStr })}
          </div>
          <div className={styles['modal-actions']}>
            <button onClick={this.props.confirm}>
              {getTranslation('sessionTimeout.acknowledge')}
            </button>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default SessionWarningModal
