import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getTranslation } from '../i18n/translate'
import ThirdPartyModal from '../modals/ThirdPartyModal.js'

class ConsentBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
    }

    this.handleOpenThirdPartyModal = this.handleOpenThirdPartyModal.bind(this)
    this.handleCloseThirdPartyModal = this.handleCloseThirdPartyModal.bind(this)
  }

  handleOpenThirdPartyModal() {
    this.setState({ openModal: true })
  }

  handleCloseThirdPartyModal() {
    this.setState({ openModal: false })
  }

  render() {
    const { openModal } = this.state
    const bannerMainStyle = {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      color: '#000',
      padding: '10px',
      textAlign: 'left',
      zIndex: 9999,
    }

    const bannerContentStyle = {
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 15px',
    }

    const bannerTextStyle = {
      width: '80%',
    }

    const bannerButtonStyle = {
      width: '81px',
      height: '40px',
      padding: '0px 16px',
      borderRadius: '4px',
      border: '1px solid #656A86', // Add the border style
      cursor: 'pointer',
      margin: '0 10px',
      backgroundColor: '#fff',
    }

    const bannerAcceptButtonStyle = {
      backgroundColor: '#1F3CD0',
      color: '#fff',
    }

    const mergedButtonStyle = {
      ...bannerButtonStyle,
      ...bannerAcceptButtonStyle,
    }

    return (
      <React.Fragment>
        {openModal && (
          <ThirdPartyModal close={this.handleCloseThirdPartyModal} />
        )}
        <div style={bannerMainStyle}>
          <div style={bannerContentStyle}>
            <div style={bannerTextStyle}>
              <h2>{getTranslation('thirdPartyBannerTitle')}</h2>
              <p>{getTranslation('thirdPartyBannerText')}</p>
            </div>
            <div>
              <a
                onClick={this.handleOpenThirdPartyModal}
                style={{ textDecoration: 'none' }}
                href="#"
                data-testid="vendorConsentModal"
              >
                {getTranslation('thirdPartyBannerLink')}
              </a>
            </div>
            <div style={{ textAlign: 'right' }}>
              <button
                style={bannerButtonStyle}
                onClick={() => this.props.handleAcceptRejectAction(false)}
                data-testid="rejectVendorConsent"
              >
                {getTranslation('thirdPartyBannerButtonReject')}
              </button>
              <button
                style={mergedButtonStyle}
                onClick={() => this.props.handleAcceptRejectAction(true)}
                data-testid="rejectVendorAccept"
              >
                {getTranslation('thirdPartyBannerButtonAccept')}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

ConsentBanner.propTypes = {
  handleAcceptRejectAction: PropTypes.func.isRequired,
}

export default ConsentBanner
