import React, { Component } from 'react'
import ReactModal from 'react-modal'
import reactModalStyle from './../modals/reactModalStyle.js'
import styles from './../modals/Modal.css'
import { getTranslation } from '../i18n/translate.js'

class EnvironmentSelectionModal extends Component {
  render() {
    const {
      selectedEnvironmentName,
      confirm,
      cancel,
      currentAppName,
    } = this.props
    // Using ReactModal that session timer uses
    return (
      <ReactModal
        isOpen
        style={reactModalStyle}
        ariaHideApp={false}
        portalClassName={styles['modal-portal']}
      >
        <div className={styles['modal-content']}>
          <h3>{getTranslation('noAppInEnv.title')}</h3>
          <div>
            {getTranslation('noAppInEnv.body', {
              application: currentAppName || getTranslation('application'),
              environment: selectedEnvironmentName,
            })}
          </div>
          <div className={styles['modal-actions']}>
            <button className={styles.cancel} onClick={cancel}>
              {getTranslation('cancel')}
            </button>
            <button className={styles.confirm} onClick={confirm}>
              {getTranslation('continue')}
            </button>
          </div>
        </div>
      </ReactModal>
    )
  }
}

// Available Properties:
// selectedEnvironmentName: string
// confirm: function
// cancel: function
// currentAppName: string

export default EnvironmentSelectionModal
