import React, { Component } from 'react'
import styles from './ArcDropdown.css'
import ApplicationList from './ApplicationList.js'
import { filterApplications } from './dashboardUtils.js'
import { getTranslation } from '../i18n/translate.js'
import faSpinner from '../../assets/img/fa/spinner.svg'

class SectionList extends Component {
  constructor(props) {
    super(props)
    this.buildSection = this.buildSection.bind(this)
  }

  generateId(key) {
    return key.replace(/\s/g, '_')
  }

  buildSection({ apps, id, text }) {
    // home section has no heading
    // and can not be collapsed
    if (id === 'home') {
      return (
        <li key={id}>
          <ApplicationList
            action={null}
            appList={apps}
            id={id}
            onChange={this.favoritesUpdated}
            trackEvent={this.props.trackEvent}
          />
        </li>
      )
    } else {
      // Pinned apps get a remove button
      // Non pinned apps get the star button
      const action = id === 'pinnedApps' ? 'remove' : 'add'
      // We use CSS to show and hide the collapsable sections
      // When checked the section collapses
      // This way we are not maintaining state in the react code
      // checkbox is hidden
      return (
        <li key={id}>
          <input
            type="checkbox"
            className={styles.cb_section}
            id={this.generateId('cb' + text)}
          />
          <label htmlFor={this.generateId('cb' + text)}>{text}</label>
          <ApplicationList
            action={action}
            appList={apps}
            id={id}
            onChange={this.props.onChange}
            trackEvent={this.props.trackEvent}
          />
        </li>
      )
    }
  }

  render() {
    const { filter, isLoading, sections } = this.props

    // If user typed something  into the filter than adjust
    // the application list which will hide sections that have
    // no matches. If no filter then just show everything.
    const filteredSections = filter.length
      ? filterApplications(sections, filter)
      : sections

    // check to see if we are loading and display a message
    // (User probably will never see this)
    if (isLoading) {
      return (
        <li className={styles.loading}>
          <a>
            <img src={faSpinner} />
            <span>{getTranslation('loading')}</span>
          </a>
        </li>
      )
    } else if (filteredSections.length === 0) {
      // If we have no sections to show, figure out
      // if it is because of a filter or there is no
      // applications available to the user.
      if (sections.length && filter.length) {
        // no filter message
        return (
          <li key="filterNoResults">{getTranslation('filterNoResults')}</li>
        )
      } else {
        // no applications message
        return <li key="noApplications">{getTranslation('noApplications')}</li>
      }
    }

    // Sections are sorted by the api so no need to adjust order
    const sectionsList = filteredSections
      .filter(({ apps }) => apps.length)
      .map(this.buildSection)
    return <ul>{sectionsList}</ul>
  }
}

export default SectionList
