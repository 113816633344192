import React, { Component } from 'react'
import styles from './ArcDropdown.css'
import FavoritesAction from './FavoritesAction.js'
import { getTranslation } from '../i18n/translate.js'

const resourcePath = `https://resources.arcpublishing.com/apps`
const genericPath = `https://resources.arcpublishing.com/dashboard/arc-icon-white.png`

class ApplicationList extends Component {
  constructor(props) {
    super(props)
    this.genLink = this.genLink.bind(this)
    this.secondaryNavLinks = this.secondaryNavLinks.bind(this)
    this.handleFavoritesError = this.handleFavoritesError.bind(this)
    this.state = {
      isError: false,
      error: null,
    }
  }

  // Trident has hard-coded values for images, we need to use white
  // so until trident can give more than one option for image
  // we have to change the path to get the white version
  imgUrl(lnk) {
    if (lnk.icon) {
      return `${resourcePath}/white/${lnk.icon}.png`
    } else if (!lnk.img || lnk.img.includes('generic_')) {
      return genericPath
    } else {
      const fileName = lnk.img.match(/\/([^/]+)\.(svg|png)$/)
      return fileName
        ? `${resourcePath}/white/${fileName[1].replace(
            /(_(navy|red|orange|green|blue))$/,
            ''
          )}.png`
        : genericPath
    }
  }

  handleFavoritesError(error) {
    this.setState({ isError: true, error })
    this.timer = window.setTimeout(() => {
      this.setState({ isError: false, error: null })
      this.timer = null
    }, 3000)
  }

  componentWillUnmount() {
    if (this.timer) window.clearTimeout(this.timer)
  }

  // If for some reason the image does not load, we will
  // swap the url back to the generic image.
  handleImgError({ target }) {
    if (target.src !== genericPath) {
      target.src = genericPath
    }
  }

  // build the list item for the application
  // app logo, text, favoriteButton
  genLink(lnk) {
    const { action, onChange } = this.props

    if (this.state.isError && lnk.title === this.state.error.appName) {
      return (
        <li key={lnk.title}>
          <a href="#">
            <span>{getTranslation(this.state.error.msg)}</span>
          </a>
        </li>
      )
    }

    return (
      <li key={lnk.title}>
        <a href={lnk.href}>
          <img
            src={this.imgUrl(lnk)}
            onError={this.handleImgError}
            className={styles.appLogo}
          />
          <span>{lnk.title}</span>
          {action && (
            <FavoritesAction
              action={action}
              appName={lnk.title}
              onChange={onChange}
              onError={this.handleFavoritesError}
              trackEvent={this.props.trackEvent}
            />
          )}
        </a>
        {this.secondaryNavLinks(lnk)}
      </li>
    )
  }

  // We do not have support for this, but we will sometime
  // in the future. The reason there is no way to easily
  // supply secondary nav links for apps.
  secondaryNavLinks(lnk) {
    if (lnk.secondary && lnk.secondary.length) {
      return <ul>{this.genSecLink(lnk.secondary)}</ul>
    } else {
      return ''
    }
  }

  genSecLink(secondaryNav) {
    return secondaryNav.map((sect, index) => (
      <li key={index}>
        <a href={sect.href}>{sect.title}</a>
      </li>
    ))
  }

  render() {
    const { appList, id } = this.props
    // The api sorts the apps, so we just need
    // to convert it to clickable items
    const lis = appList.map((app) => this.genLink(app))
    return <ul key={`section_${id}`}>{lis}</ul>
  }
}

export default ApplicationList
