import pathToRegexp from 'path-to-regexp'

const patternCache = {}

const compile = (pattern, options) => {
  const cacheKey = `${options.end}${options.strict}${options.sensitive}`
  const cache = patternCache[cacheKey] || (patternCache[cacheKey] = {})

  if (cache[pattern]) {
    return cache[pattern]
  }

  const re = pathToRegexp(pattern, null, options)

  cache[pattern] = re

  return re
}

const parseSearch = (search) => {
  const params = {}
  search
    // strip leading ?, but don't be strict about its existence
    .replace(/^\?/, '')
    .split('&')
    .forEach((param) => {
      const [key, ...values] = param.split('=')
      params[key] = values.join('=')
    })
  return params
}

const { URL } = window

const isMatch = (location, { href: mHref, match }) => {
  if (!mHref && !match) {
    return false
  }

  const { href, origin, pathname, search, hash } = location

  const url = new URL(mHref, href)

  if (!match || !(match.hash || match.path)) {
    return (
      origin === url.origin && pathname === url.pathname && hash === url.hash
    )
  }

  const {
    path: pathPattern,
    search: searchPatterns,
    hash: hashPattern,
    end = false,
    strict = false,
    sensitive = false,
  } = match

  const pathMatch = pathPattern
    ? compile(pathPattern, { end, strict, sensitive }).test(pathname)
    : true

  const searchKeys = searchPatterns && Object.keys(searchPatterns)
  const doSearchMatch = searchKeys && searchKeys.length
  const searchParams = doSearchMatch && parseSearch(search)
  const searchMatch = doSearchMatch
    ? searchKeys.every((searchKey) =>
        compile(searchPatterns[searchKey], {
          end,
          sensitive,
          strict: true,
        }).test(searchParams[searchKey])
      )
    : true

  const hashMatch = hashPattern
    ? compile(hashPattern, { end, strict, sensitive }).test(hash.slice(1))
    : true

  return origin === url.origin && pathMatch && searchMatch && hashMatch
}

export default isMatch
