import React, { Component } from 'react'
import styles from './ArcDropdown.css'
import faTimes from '../../assets/img/fa/times.svg'
import faSpinner from '../../assets/img/fa/spinner.svg'
import faStar from '../../assets/img/fa/star-o.svg'
// TODO when ready, move this to use the npm package
import { addToFavorites, removeFromFavorites } from './dashboardUtils.js'

class FavoritesAction extends Component {
  constructor(props) {
    super(props)
    this.handleActionClick.bind(this)
    this.state = {
      isUpdating: false,
    }
  }

  // called when the user clicks the star or x button
  handleActionClick(evt) {
    const { action, appName, trackEvent } = this.props
    evt.preventDefault()
    evt.stopPropagation()
    // determine if we are adding or removing
    const method = action === 'add' ? addToFavorites : removeFromFavorites

    // Determine whether this is a 'Pin' or 'Unpin' action
    const analyticsAction = action === 'add' ? 'Pin' : 'Unpin'

    // Call the method and trigger the change event,
    // so the parent can render the updated lists
    this.setState({ isUpdating: true })
    method(appName)
      .then((result) => {
        this.setState({ isUpdating: false })
        this.props.onChange(result)

        trackEvent({
          category: 'Pinned Apps',
          action: `${analyticsAction}:${appName}`,
          label: 'Success',
        })
      })
      .catch((e) => {
        this.setState({ isUpdating: false })
        console.error(e)
        const msgString =
          action === 'add' ? 'alreadyInFavorites' : 'notInFavorites'
        this.props.onError &&
          this.props.onError(
            Object.assign(e, { appName, msg: `errors.${msgString}` })
          )

        trackEvent({
          category: 'Pinned Apps',
          action: `${analyticsAction}:${appName}`,
          label: 'Error',
        })
      })
  }

  render() {
    // pinned gets the X and unpinned apps get a star
    // if it is clicked, it gets the loading icon to let
    // user knows their click was registered.
    const icon = this.state.isUpdating
      ? faSpinner
      : this.props.action === 'add'
      ? faStar
      : faTimes

    const additionalClassName = this.state.isUpdating
      ? `${styles.updating}`
      : ''
    const imgClassName = `${styles.pinButton} ${additionalClassName}`

    // adds the image that gets rendered into the app button
    // clicking on it triggers the add/remove
    return (
      <img
        data-pingaction={this.props.action}
        data-testid={this.props.action}
        onClick={(evt) => this.handleActionClick(evt)}
        className={imgClassName}
        src={icon}
      />
    )
  }
}

export default FavoritesAction
