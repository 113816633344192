import features from '@wpmedia/arc-features'
import featureList from './featureList.json'

/* This is just extending arc-features and initializing it.
The featureList.json file is what you update to set the
enabled/disabled state of a feature. AKA we can turn off
restrictions by altering it in the feature file. So when
we push out a feature and we have a bug, we can just turn
it off without rolling back all the code in a branch.  */

export default () => {
  // Return an initialized features instance.
  return features.instance({ flags: featureList })
}
