import React from 'react'
import ReactModal from 'react-modal'
import reactModalStyle from './reactModalStyle.js'
import { getTranslation } from '../i18n/translate'
import styles from './Modal.css'

export default (props) => {
  return (
    <ReactModal
      isOpen
      style={reactModalStyle}
      ariaHideApp={false}
      portalClassName={styles['modal-portal']}
    >
      <div className={styles['modal-content']}>
        <h3>{getTranslation('sessionTimeout.expiredHeader')}</h3>
        <div>{getTranslation('sessionTimeout.expiredBody')}</div>
        <div className={styles['modal-actions']}>
          <button onClick={props.confirm} data-testid="sessionExpirationModal">
            {getTranslation('sessionTimeout.signIn')}
          </button>
        </div>
      </div>
    </ReactModal>
  )
}
