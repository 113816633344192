const crypto = require('crypto')

const awsEnvKey = process.env.PENDO_ACCOUNT_SECRET_KEY || ''
const secretKey = Buffer.from(awsEnvKey, 'hex')
const iv = Buffer.from(awsEnvKey.slice(-32), 'hex')

// Encrypt the account id (organization) data
function encrypt(text, key, iv) {
  try {
    const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv)
    let encrypted = cipher.update(text, 'utf8', 'hex')
    encrypted += cipher.final('hex')
    return iv.toString('hex') + encrypted
  } catch (error) {
    // Handle the encryption error here
    console.error('Encryption error:', error.message)
    return 'NoAccountID'
  }
}

const encryptedData = encrypt(
  window.localStorage.getItem('arc.organization'),
  secretKey,
  iv
)

const AccountID = encryptedData.slice(-32)

const PENDO_API_KEY = process.env.PENDO_API_KEY || ''

export const configScript = `
(function(apiKey){
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
  v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('${PENDO_API_KEY}');
`

export const config = () => {
  const scriptAddPendo = document.createElement('script')
  scriptAddPendo.innerHTML = configScript
  document.body.appendChild(scriptAddPendo)
}

export const initialize = (userId) => {
  const initializeScript = `
      function determinePageUrl() {
        const inputUrl = window && window.location && window.location.href
        const regex = /^https?:\\/\\/(?:([^.]+)\\.)?([^/]+)\\.arcpublishing\\.com(\\/.*)?$/
        const match = inputUrl.match(regex)
        if (!match) {
          throw new Error('Unsupported URL format')
        }
        let environment = match[1] !== undefined ? match[1] + '.' : ''
        const path = match[3] !== undefined ? match[3] : ''
        const finalUrl = 'https://' + (environment || '') + 'arcpublishing.com' + path
        return finalUrl
      }

      pendo.initialize({
        visitor: {
            id: '${userId}'
        },
        account: {
            id: '${AccountID}'
        },
        location: {
          setUrl: function () { return determinePageUrl(); },
          transforms: [
              {
                'attr': 'search',
                'action': 'Clear'
              } 
          ]
        }
      });
      `
  const scriptInitPendo = document.createElement('script')
  scriptInitPendo.innerHTML = initializeScript
  document.body.appendChild(scriptInitPendo)
}

// You can use the following function in order to decrypt the org name
// function decrypt(encryptedText, key) {
//   const iv = Buffer.from(encryptedText.slice(0, 32), 'hex')
//   const encryptedData = encryptedText.slice(32)
//   const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv)
//   let decrypted = decipher.update(encryptedData, 'hex', 'utf8')
//   decrypted += decipher.final('utf8')
//   return decrypted
// }
// const decryptedData = decrypt(encryptedData, secretKey)
// console.log('Decrypted data: ', decryptedData)
