import React from 'react'
import ReactModal from 'react-modal'
import reactModalStyle from './reactModalStyle.js'
import { getTranslation } from '../i18n/translate'
import styles from './Modal.css'
import arcLogo from '../../assets/img/arc-modal-logo.svg'

export default (props) => {
  let jsonData = require('./vendorList.json')
  const vendorItems = jsonData.vendors.map((item, index) => (
    <li key={index} style={{ listStyleType: 'none' }}>
      {item}
    </li>
  ))

  return (
    <ReactModal
      isOpen
      style={reactModalStyle}
      ariaHideApp={false}
      portalClassName={styles['modal-portal']}
    >
      <div className={styles['modal-content']}>
        <img src={arcLogo} alt="logo" />
        <h4>{getTranslation('thirdPartyBannerModalVendorList')}</h4>
        <ul style={{ marginTop: '-1em' }}>{vendorItems}</ul>
        <div className={styles['modal-actions']}>
          <button onClick={props.close} data-testid="closeVendorConsentModal">
            Close
          </button>
        </div>
      </div>
    </ReactModal>
  )
}
