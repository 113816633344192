import { Component } from 'react'

class ArcDropdownPosition extends Component {
  constructor(props) {
    super(props)
    this.defaultBarHeight = 60
    this.state = {
      positionTop: this.defaultBarHeight,
    }
    this.positionDropdownHandler = this.positionDropdown.bind(this)
    this.lastState = props.isOpen
  }

  componentDidMount() {
    window.addEventListener('scroll', this.positionDropdownHandler)
  }

  componentDidUpdate() {
    if (this.lastState !== this.props.isOpen) {
      this.lastState = this.props.isOpen
      if (this.props.isOpen) {
        this.positionDropdown()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.positionDropdownHandler)
  }

  positionDropdown() {
    if (this.props.isOpen) {
      let positionTop =
        this.defaultBarHeight - document.documentElement.scrollTop
      if (positionTop < 0) {
        positionTop = 0
      }
      this.setState({ positionTop })
    }
  }

  render() {
    return this.props.children(this.state)
  }
}

export default ArcDropdownPosition
