import * as translations from '../../assets/translations'

// Utility method to flatten translations dictionary
export const flattenTranslations = (
  nestedTranslations,
  dict = {},
  prefix = ''
) => {
  return Object.keys(nestedTranslations).reduce((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key
    const value = nestedTranslations[key]
    if (typeof value === 'string') {
      acc[newKey] = value
    } else {
      flattenTranslations(value, acc, newKey)
    }
    return acc
  }, dict)
}

// Build a dictionary with keys corresponding to each JSON translation file
const i18n = Object.keys(translations).reduce((dict, key) => {
  dict[key] = flattenTranslations(translations[key])
  return dict
}, {})

// Select the first language in the `navigator.languages` list, if available;
// Fall back to the `navigator.language` or `navigator.userLanguage`, if not;
// Default to 'en-US'
export const getBrowserLocale = (navigator) => {
  const [locale = 'en-US'] =
    navigator && Array.isArray(navigator.languages)
      ? navigator.languages
      : [].concat(navigator && (navigator.language || navigator.userLanguage))
  return locale
}

const browserLocale = getBrowserLocale(window.navigator)

// The two-letter language key is a fallback to be used if there are not
// fully localized translations (e.g., 'en' instead of 'en-US')
const languageKey = browserLocale.slice(0, 2)

// Helper function to get the text from the link object based on language
export const getLinkTranslation = (linkObj) => {
  let text
  if (linkObj.text) {
    // see if we have a dictionary
    text =
      linkObj.text[browserLocale] || // look for full country code (e.g. 'en-US')
      linkObj.text[languageKey] || // look for just the lang code (e.g. 'en')
      linkObj.text['en'] // fall back to the default if we do not have the translation
  }
  if (!text) {
    // If text is still not set, that fall back to the name property
    text = linkObj.name || 'UNKNOWN'
  }
  return text
}

// Helper method to interpolate values in the string
export const interpolateValues = (string, values) => {
  return Object.keys(values).reduce((result, key) => {
    return result.replace(`{${key}}`, values[key])
  }, string)
}

export const getTranslation = (key, options = null) => {
  let text = key
  const dictionary = i18n[browserLocale] || i18n[languageKey] || i18n['en']
  if (dictionary && dictionary[key] && options) {
    text = interpolateValues(dictionary[key], options)
  } else if (dictionary && dictionary[key]) {
    text = dictionary[key]
  }
  return text
}
