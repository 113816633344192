/**
 * Retrieve the apps from Trident.
 */
async function _fetchApps () {
  const fetchSettings = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include'
  }

  const res = await window.fetch(`/apps`, fetchSettings)
  if (res.ok) {
    return res.json()
  } else {
    return res.Error
  }
}

/**
 * Promise the apps on initialization.
 */
const fetchAppsPromise = _fetchApps()

/**
 * Return the pre-initialized promise.
 */
const fetchApps = () => {
  return fetchAppsPromise
}

export default {
  fetchApps
}
