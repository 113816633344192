import React, { Component } from 'react'
import styles from './ArcDropdown.css'
import logo from '../../assets/img/arc-logo.svg'
import logoBlue from '../../assets/img/arc-logo-blue.svg'
import faSearch from '../../assets/img/fa/search.svg'
import { getTranslation } from '../i18n/translate.js'
import ArcDropdownPosition from './ArcDropdownPosition.js'
import { dashboardDataInstance } from './dashboardDataInstance'
import SectionList from './SectionList.js'
import EnvironmentSelection from './EnvironmentSelection.js'
import PropTypes from 'prop-types'

class ArcDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.defaultOpen || false,
      environments: [],
      orderedSections: [],
      name: '',
      selectEnvOpen: false,
    }

    this.setDropdownElement = (el) => {
      this.dropdownElement = el
    }

    this.favoritesUpdated = this.favoritesUpdated.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  handleOutsideClick(e) {
    const isElem =
      this.dropdownElement.contains(e.target) ||
      document.querySelector('.' + styles.dd_menu).contains(e.target) ||
      (e.target.dataset && e.target.dataset.pingaction)
    if (!isElem) {
      this.toggle()
    }
  }

  toggle(event) {
    // Do not show sub menu if feature flag is disabled
    if (!this.props.isEnabled) {
      // do not follow link if in modal view
      if (this.props.disableArcLogoHref) {
        event && event.preventDefault()
        return false
      }
      // else follow the link
      return true
    }

    event && event.preventDefault()
    this.state.isOpen
      ? document.removeEventListener('click', this.handleOutsideClick)
      : document.addEventListener('click', this.handleOutsideClick)

    this.setState((prev) => ({ isOpen: !prev.isOpen }))
  }

  resetSelectionStateData() {
    this.setState({
      orderedSections: [],
      name: '',
    })
  }

  loadAppData() {
    this.setState({ isLoading: true })
    this.resetSelectionStateData()

    if (this.props.disableFetchCalls) {
      this.setState({
        environments: [],
        favorites: [],
        name: '',
        isLoading: false,
      })
      return null
    }

    dashboardDataInstance
      .then(({ environments, name, orderedSections = [] }) => {
        if (this.mounted) {
          this.setState({
            environments,
            orderedSections,
            name,
            isLoading: false,
          })
        }
      })
      .catch(
        (e) =>
          this.mounted &&
          this.setState({
            environments: [],
            favorites: [],
            name: '',
            isLoading: false,
          })
      )
  }

  filterList(evt) {
    this.setState({ filter: evt.target.value.toLowerCase() })
  }

  componentDidMount() {
    this.mounted = true
    this.loadAppData()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  generateSearchInput() {
    return this.state.orderedSections.length ? (
      <li className={styles.environment}>
        <img className={styles.bgSearchIcon} src={faSearch} />
        <input
          type="search"
          id="filter_arc_dd"
          placeholder={getTranslation('searchPlaceholder')}
          className={styles.search}
          onInput={this.filterList.bind(this)}
        />
      </li>
    ) : (
      ''
    )
  }

  favoritesUpdated(pinnedApps) {
    try {
      const orderedSections = this.state.orderedSections

      const normalizeName = (name) => name.replace(/\s/g, '').toLowerCase()
      const pinnedSection = orderedSections[1]

      // Make an easy way to look up apps by name
      const currentlyPinnedLookUp = pinnedSection.apps.reduce((o, app) => {
        o[normalizeName(app.title)] = app
        return o
      }, {})

      // find apps that were removed an place them into the correct sections
      Object.keys(currentlyPinnedLookUp).forEach((key) => {
        // if we do not have it than it was remobed
        if (!pinnedApps.includes(key)) {
          // get the app reference and find what section it was in
          const removedApp = currentlyPinnedLookUp[key]
          const sectionName = removedApp.section
          const sectionAddTo = orderedSections.find(
            (sect) => sect.section === sectionName
          )
          // Add the app to the section and reoder based on the title
          sectionAddTo.apps.push(removedApp)
          sectionAddTo.apps.sort((a, b) => a.title.localeCompare(b.title))
          // remove the app from the pinned section
          const pIndex = pinnedSection.apps.findIndex(
            (app) => normalizeName(app.title) === key
          )
          pinnedSection.apps.splice(pIndex, 1)
        }
      })

      // Find applications that were added to the pinned favorites
      let foundNew = false
      pinnedApps.forEach((appName) => {
        if (!currentlyPinnedLookUp[appName]) {
          foundNew = true
          // skipping first two indexes since it will not be in them
          for (let i = 2; i < orderedSections.length; i++) {
            const sectionApps = orderedSections[i].apps
            for (let j = 0; j < sectionApps.length; j++) {
              if (normalizeName(sectionApps[j].title) === appName) {
                // added to pinned section
                pinnedSection.apps.push(sectionApps[j])
                // remove from current section
                sectionApps.splice(j, 1)
                // exit the loops
                i = orderedSections.length
                break
              }
            }
          }
        }
      })

      // if we added apps to the pinned section, sort it
      if (foundNew) {
        pinnedSection.apps.sort((a, b) => a.title.localeCompare(b.title))
      }

      // render the list again
      this.setState({ orderedSections })
    } catch (e) {
      // If an item is in a favorited section by itself, the api does not return the section
      // So when we try to access it, it will throw an error since it is not there.
      // So we will need to call the server and get the updated section list
      console.error(e)
      this.loadAppData()
    }
  }

  render() {
    const {
      isOpen,
      environments = [],
      orderedSections,
      name,
      isLoading,
      filter = '',
    } = this.state
    const menuClass =
      styles.dd_menu +
      (isOpen ? ' ' + styles.open : '') +
      (isLoading ? ' ' + styles.loadingData : '') +
      (environments.length <= 1 ? ' ' + styles.hide_envs : '')

    const brandStyles =
      styles.brand +
      (this.props.disableArcLogoHref ? ' ' + styles.notLinked : '') +
      (this.state.isOpen ? ' ' + styles.dropdownActive : '')

    const arcLogo = this.props.isEnabled ? logoBlue : logo
    return (
      <div
        className={brandStyles}
        ref={this.setDropdownElement}
        onClick={(evt) => evt.stopPropagation()}
      >
        <a href="/" onClick={this.toggle}>
          <img
            src={arcLogo}
            alt="logo"
            data-testid={
              this.props.isEnabled ? 'brand-logo-image' : 'brand-logo-link'
            }
          />
        </a>
        <ArcDropdownPosition isOpen={this.state.isOpen}>
          {({ positionTop }) => (
            <div className={menuClass} style={{ top: positionTop }}>
              <div className={styles.scrollFixed} style={{ top: positionTop }}>
                <EnvironmentSelection
                  currentAppName={this.props.appname}
                  environments={environments}
                  selectedEnvironmentName={name}
                />
              </div>
              <div className={styles.appList}>
                <ul>{this.generateSearchInput()}</ul>
                <SectionList
                  isLoading={isLoading}
                  filter={filter}
                  sections={orderedSections}
                  onChange={this.favoritesUpdated}
                />
              </div>
            </div>
          )}
        </ArcDropdownPosition>
      </div>
    )
  }
}

ArcDropdown.propTypes = {
  appname: PropTypes.string.isRequired,
  disableArcLogoHref: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
}

export default ArcDropdown
