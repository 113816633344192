import React, { Component } from 'react'
import styles from './ArcDropdown.css'
import { getTranslation } from '../i18n/translate.js'
import { fetchDashboardData } from './dashboardUtils.js'
import EnvironmentSelectionModal from './EnvironmentSelectionModal.js'

class EnvironmentSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      environmentModalDisplayed: false,
      selectedEnvironmentName: null,
      selectedBasePath: null,
    }
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  componentWillUnmount() {
    this.removeOutsideClickHandler()
  }

  removeOutsideClickHandler() {
    document.body.removeEventListener('mousedown', this.handleOutsideClick)
  }

  addOutsideClickHandler() {
    document.body.addEventListener('mousedown', this.handleOutsideClick)
  }

  toggleState(evt) {
    // Add/Remove the global event listener based on checkbox state
    const checked = evt.target.checked
    if (checked) {
      this.addOutsideClickHandler()
    } else {
      this.removeOutsideClickHandler()
    }
  }

  // determine if click is outside of the element to hide it
  handleOutsideClick(evt) {
    const className = styles.envSelector
    const clickedElem = evt.target
    const dropdown = document.querySelector(`.${className}`)
    // make sure we are not inside of the dropdown
    const hasClass = clickedElem.classList.contains(className)
    if (
      clickedElem &&
      dropdown &&
      !hasClass &&
      !dropdown.contains(clickedElem)
    ) {
      // if we are in the dropdown toggle the checkbox that controls the display
      const cb = document.querySelector(`.${className} input[type="checkbox"]`)
      if (cb) {
        cb.checked = false
      }
      this.removeOutsideClickHandler()
    }
  }

  splitEnvironments() {
    // Environments are either prod or sandbox based on a flag
    // This will split them up into the two buckets based on the flag
    const { environments = [], selectedEnvironmentName } = this.props
    const envs = environments.reduce(
      (obj, env) => {
        // If it is the current environment, do not include in the set
        if (env.name !== selectedEnvironmentName) {
          // push to the correct array based on production boolean
          if (env.production) {
            obj.production.push(env)
          } else {
            obj.sandbox.push(env)
          }
        }
        return obj
      },
      { production: [], sandbox: [] }
    )
    return envs
  }

  generateEnvironmentElements(environments, type) {
    // builds the list items for the environment drop down.
    return environments.map((env) => (
      <li
        onClick={this.environmentChange.bind(this)}
        className={`envOption ${type}`}
        key={env.name}
        data-value={env.name}
      >
        {getTranslation(env.name)}
      </li>
    ))
  }

  generateEnvSelector() {
    const { selectedEnvironmentName } = this.props
    // Split prod and sandbox environments apart
    // The dropdown displays prod on top seperated with a line
    // between prod and sandbox environments
    const { production, sandbox } = this.splitEnvironments()
    const prodEnvs = this.generateEnvironmentElements(production, styles.prod)
    const sandboxEnvs = this.generateEnvironmentElements(
      sandbox,
      styles.sandbox
    )

    // Use css rule with checked, it shows and hides the dropdown,
    // not using state variable to control visibility
    return (
      <div className={styles.envSelector}>
        <label onClick={(evt) => evt.stopPropagation()}>
          <input type="checkbox" onChange={this.toggleState.bind(this)} />
          <span className={styles.selected} data-testid="environment-dropdown">
            {getTranslation(selectedEnvironmentName)}
          </span>
          <ul>
            {prodEnvs}
            {sandboxEnvs}
          </ul>
        </label>
      </div>
    )
  }

  environmentChange(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    // Find the details of the selected environment from the array
    // so we can fetch its dashboard json file to get the applications
    // available for it
    const selectedName = evt.target.dataset.value
    const { environments } = this.props
    const selectedEnvironment = environments.find(
      (env) => env.name === selectedName
    )
    const path = `//${selectedEnvironment.domain}`
    fetchDashboardData(path, true) // grab dashboard data (CORS Request)
      .then(this.checkIfApplicationExistsInEnvironment) // determine if we have app
      .then((result) => {
        // boolean if app exists in selected environment
        if (result) {
          // exists so go to it
          this.navigateToPage(path)
        } else {
          // does not exist so display prompt
          this.displayPrompt(path, selectedEnvironment.name)
        }
      })
  }

  checkIfApplicationExistsInEnvironment({ applications }) {
    // This code we are taking the pathname and looping over
    // the list of app paths in the dashboard json and find the
    // best match. If the there are mutliple matches we are picking
    // the longer one. EG: /websked vs /websked/collections
    // There is no "application id" so there is no easy look up
    const currentPage = window.location.pathname
    // if home than just push to home else loop over and figure it out
    const site =
      currentPage.indexOf('/home/') === 0 ||
      applications.reduce((current, app) => {
        // if dashboard has route use it, use build the route
        let route
        try {
          route = app.route || new URL(app.href).pathname
        } catch (e) {
          console.log('problem reading', app, e)
          return current
        }
        // length 1 is to ignore home/base path which is "/"
        if (route && route.length > 1) {
          // see if the current contains the route
          // and that the route is bigger than what we have
          if (currentPage.includes(route) && route.length > current.length) {
            return route
          }
        }
        return current
      }, '').length > 0
    return site
  }

  navigateToPage(basePath) {
    // This is going to fail if they are on Ellipsis or Anglerfish, etc
    // if they are on a specific story, but there is really no way for us
    // to know if they are without a list of paths and how to redirect to it
    window.location.href =
      basePath + window.location.href.replace(/https?:\/\/[^/]+/, '')
  }

  navigateToHome() {
    // just going to the base path, the redirect will push them to homew
    window.location.href = this.state.selectedBasePath
  }

  displayPrompt(selectedBasePath, selectedEnvironmentName) {
    // set the state variables so modal shows
    this.setState({
      environmentModalDisplayed: true,
      selectedEnvironmentName,
      selectedBasePath,
    })
  }

  render() {
    if (this.state.environmentModalDisplayed) {
      // Using the modal like Session Timeout Uses
      return (
        <EnvironmentSelectionModal
          currentAppName={this.props.currentAppName}
          selectedEnvironmentName={this.state.selectedEnvironmentName}
          confirm={this.navigateToHome.bind(this)}
          cancel={() => {
            this.setState({ environmentModalDisplayed: false })
          }}
        />
      )
    } else {
      // displays the environment dropdown on the page
      return (
        <ul>
          <li className={styles.environment}>
            <label>{getTranslation('environment')}</label>
            {this.generateEnvSelector()}
          </li>
        </ul>
      )
    }
  }
}

export default EnvironmentSelection
